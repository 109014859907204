<template lang="pug">
  section.section.banner
    div.container
      div.benefit__box
        div.benefit__content.content
          h1.h1.benefit__title.white Utimate URL shortener and tracking service
          div.text.benefit__text.white F4.TO allows you to create short links, track clicks, and optimize your marketing campaigns.
          a(v-scroll-to="'#use'")(@click="clickBtn").btn About service
        div.benefit__img
          div.benefit_img-inner
            img(src="~@/assets/images/home-img.png" alt="Benefit image")

</template>

<script>

export default {
  name: 'BannerBlock',
  methods: {
    clickBtn () {
      let menuItem = document.querySelectorAll('.navigation__item')
      for(let i = 0; i < menuItem.length; i++) {
        menuItem[i].classList.remove('active');
        menuItem[2].classList.add('active');
      }
    }
  }
}

</script>
